import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { environment } from 'src/environments/environment';
import { AuthenticationCallbackState } from '~modules/thirdparts/common/entities/authentication-callback-state.constant';
import { NextMotionState } from '../entities/third-parts-authentication-next-motion-state.entity';
import { ThirdPartsAuthenticationNextMotionCreateUpdateResponse } from '../entities/third-parts-authentication-next-motion-create-update-response.entity';

@Injectable()
export class ThirdPartsNextMotionAuthenticationService {

  constructor(private readonly http: HttpClient) { }

  /**
   * Generate a link to the authenticate page of account.nextmotion.net and then do a redirection to this link
   * @param state the state to restore the application
   */
  public authorizeNextMotionApplication(state: string): void {
    const url =
      `${ environment.nextMotion.oauth2.authorizeUrl }` +
      `?client_id=${ environment.nextMotion.oauth2.clientId }` +
      `&redirect_uri=${ encodeURIComponent( `${ window.location.origin }/app/third-parts/next-motion/callback` ) }` +
      `&response_type=code` +
      `&scope=${ encodeURIComponent( environment.nextMotion.oauth2.scopes ) }` +
      `&state=${ state }`;

    window.location.href = url;
  }

  /**
   * Create nextmotion authentication from authorization key
   * @param authorizationCode Authorization code from nextmotion
   */
  public createUpdateAuthenticationFromAuthorisationCode(authorizationCode: string): Observable<ThirdPartsAuthenticationNextMotionCreateUpdateResponse> {
    const path = `/api/third-parts/next-motion/authentication/create-update-from-authorization-token`;
    return this.http.post<ThirdPartsAuthenticationNextMotionCreateUpdateResponse>(path, {
      authorizationCode
    });
  }


  /**
   * Save a state with an unique identifier returned
   * @param state state to store
   */
  public generateIdentifierAndCodeChallengeAndSaveState(callbackState: AuthenticationCallbackState): NextMotionState {
    // Generate identifier (toString(36) converts the number in base 36 (numbers + letters) and so reduce number size)
    const id = Math.random().toString(36);
    const state: NextMotionState = {
      id,
      state: callbackState
    }
    localStorage.setItem('nextMotionState', JSON.stringify(state));
    return state;
  }

  /**
   * Return the state saved for nextmotion
   * @param id id to check
   */
  public getState(): NextMotionState {
    const stringState = localStorage.getItem('nextMotionState');
    return stringState != null ? JSON.parse(stringState) : null;
  }

  public list$(): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/next-motion/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path);
  }
}
